*, *:before, *:after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-weight: 400;
  src: url(../pxiKyp0ihIEF2isQFJXGdg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-weight: 400;
  src: url(../pxiKyp0ihIEF2isRFJXGdg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-weight: 400;
  src: url(../pxiKyp0ihIEF2isfFJU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 16px;
}

canvas {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

body {
    border-image: linear-gradient(60deg, #b1aa8b4f 0%, #0000 50%, #b1aa8b4f 100%) 10;
    border-width: 10px;
    border-style: solid;
    background-image: radial-gradient(circle at top, #35342fff , #0000),
                      linear-gradient(135deg, #060413 0%, #464040 100%);
}

puppy-view canvas {
    /* important because overriding the render dimensions */
    height: auto !important; 
    width: 100% !important;
    aspect-ratio: 1 / 1;
    margin: auto;
}

.options {
    border-image: linear-gradient(60deg, #b1aa8b4f 0%, #0000 50%, #b1aa8b4f 100%) 10;
    border-width: 4px;
    border-style: solid;
    overflow-y: auto;
    min-width: 20em;
    margin: 10px;
}

input {
  all: unset;
}

copy-element .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

copy-element .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.3em;
  position: absolute;
  z-index: 6;
  bottom: 120%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.2s;
}

copy-element:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.list-item {
    padding: 0.22em 0.35em;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}


.hover-1 {
  box-shadow: inset 0 0 0 0 #f5eac144;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.hover-1:hover {
  box-shadow: inset 1000px 0 0 0 #f5eac144;
  color: white;
}

.transition-transform {
    transition: transform .25s;
    transform-origin: top;
}
